import routes from './routes';
import { useRoutes, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { currentLocationAction } from './store/location/currentLocationSlice';
import { getLocations } from './store/location/locationsSlice';
import usePageTracking from './hooks/usePageTracking';
import useSessionExpire from './hooks/useSessionExpire';
import { getMyProfile } from './store/myProfileSlice';

export const LazyLoader = () => {

   return (<>
      <div className="spinner-border text-success" role="status">
         <span className="visually-hidden">Loading...</span>
      </div>
   </>)
}


function App() {

   //  Google Analytics 4 Tracking Code
   usePageTracking();

   // Logout the system after 15 min if there is no activity

   useSessionExpire();

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const authData = useSelector(state => state.auth);
   const location = useLocation();
   const routing = useRoutes(routes(authData,location));
   const { locations, isSuccess } = useSelector(state => state.locations);
   const token = searchParams.get('token');
   const redirect = searchParams.get('redirect');

   // console.log("authData-->>", authData);
   /*
   console.log("{process.env.NODE_ENV--->>>}", process.env.NODE_ENV);
   console.log("{process.env.NODE_ENV--->>>}", process.env.NODE_ENV)
   console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL);
   console.log('process.env.REACT_APP_VETSPIRE_API', process.env.REACT_APP_VETSPIRE_API);
   */


   useEffect(() => {
      // Set user current postion
      if (navigator && navigator.geolocation) {

         navigator.geolocation.getCurrentPosition(function (position) {
            dispatch(currentLocationAction.setCurrentPosition(position))
         });
      }

   }, [dispatch, currentLocationAction]);

   // For loading the location data 

   useEffect(() => {

      if (!isSuccess)
         dispatch(getLocations());
   }, [dispatch, getLocations, isSuccess]);


   useEffect(() => {

      if (authData.isSignedIn && token && redirect == 'true') {
         navigate("/my-profile");
      }
   }, [token, redirect, authData.isSignedIn, navigate])

   useEffect(() => {

      if (authData.isSignedIn && token && location?.state) {
         
         navigate(location?.state);
      }
   }, [token, authData.isSignedIn, navigate, location])


   // Update user profile  details 

   useEffect(() => {
      if (authData.isSignedIn && !authData.isSuccess && !authData.isLoading)
         dispatch(getMyProfile());
   }, [dispatch, getMyProfile, authData]);



   return (
      <>
         <Suspense fallback={null}>
            {routing}
         </Suspense>

      </>
   );
}



export default App;
